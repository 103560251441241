import {Button, FormControl, FormGroup, FormLabel, Grid, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {FieldArray, Form, Formik} from "formik";
import {FormField} from "../../formComponents/FormField";
import * as Yup from "yup";
import {ServiceEndpoint} from "../../../constants";
import {useAuth} from "../../../services/authService";
import {useSnack} from "../../../services/snackService";
import {useHistory} from "react-router-dom";
import {RoleCheckBox} from "./common";

const validationSchema = Yup.object({
  name: Yup
    .string('Enter the full name.')
    .required('Name is required.'),
  username: Yup
    .string('Enter a email.')
    .email('Must be a valid email.')
    .required('Email is required.'),
  password: Yup
    .string('Enter a password.')
    .min(8)
    .max(255)
    .required('Password is required'),
});

const useStyles = makeStyles((theme) => ({
  inputs: {
    marginTop: theme.spacing(1)
  }
}))

export const AddUser = () => {
  const classes = useStyles();
  const history = useHistory();
  const {user} = useAuth();
  const {applySnack, applyGenericErrorSnack} = useSnack();
  const initalValues = {
    name: "",
    username: "",
    password: "",
    roles: []
  }

  const handleSubmit = async (values, actions) => {
    const err = applyGenericErrorSnack("add new user")
    let url = ServiceEndpoint + `/api/1/user`
    let resp = await fetch(url, {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + user.session_id
      },
      body: JSON.stringify(values)
    }).catch(err)
    if (resp.ok) {
      resp = await resp.json().catch(err)
      history.push("/admin/users")
      applySnack({
        severity: "success",
        message: "User Created Successfully"
      });
      actions.resetForm();
    } else {
      err()
    }
    actions.setSubmitting(false)
  }

  return (
    <Formik
      initialValues={initalValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {formik => (
        <Form>
          <Grid container direction="column">
            <Typography variant="h5" className={classes.inputs}>
              Add User
            </Typography>
            <FormField label="Name" name="name" formik={formik} className={classes.inputs}/>
            <FormField label="Email" name="username" formik={formik} className={classes.inputs}/>
            <FormField label="Password" name="password" type="password" formik={formik} className={classes.inputs}/>
            <FieldArray
              name="roles"
              render={arrayHelpers => (
                <FormControl component="fieldset" className={classes.inputs}>
                  <FormLabel component="legend">Roles:</FormLabel>
                  <FormGroup>
                    <RoleCheckBox name="Admin" label="Admin" roles={formik.values.roles} arrayHelpers={arrayHelpers}/>
                  </FormGroup>
                </FormControl>
              )}
            />
            <Button type="submit" variant="contained" color="primary">Create User</Button>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}