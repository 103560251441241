import PropTypes from "prop-types";
import {Route, useHistory} from 'react-router-dom'
import {useAuth} from "../../services/authService";
import {useSnack} from "../../services/snackService";
import {useCallback, useEffect, useState} from "react";
import {ServiceEndpoint} from "../../constants";
import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import {AddUser} from "./users/AddUser";
import {UpdateUser} from "./users/UpdateUser";

const UserTable = ({users}) => {
  const history = useHistory()

  const row = (user) => {
    return (
      <TableRow key={user.id} onClick={() => history.push(`users/${user.id}/manage`)}>
        <TableCell>{user.name}</TableCell>
        <TableCell>{user.roles && user.roles.join(", ")}</TableCell>
        <TableCell>{user.is_active? "Active" : "Not Active"}</TableCell>
        <TableCell>{user.locked_until? "Locked" : "Not Locked"}</TableCell>
      </TableRow>
    );
  }

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Roles</TableCell>
            <TableCell>Is Active?</TableCell>
            <TableCell>Is Locked?</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell colSpan={70}>
              <Button
                variant="contained"
                color="primary"
                style={{width: "100%"}}
                onClick={() => history.push("users/add")}
              >
                Add User
              </Button>
            </TableCell>
          </TableRow>
          {users && users.map(row)}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

UserTable.prototype = {
  users: PropTypes.arrayOf({
    name: PropTypes.string
  }).isRequired
}

const MainUsersPage = () => {
  const {user} = useAuth();
  const {applyGenericErrorSnack} = useSnack();
  const [users, setUsers] = useState(null);

  const getUsers = useCallback(async () => {
    const err = applyGenericErrorSnack("fetch user")
    let resp = await fetch(ServiceEndpoint + "/api/1/user", {
      headers: {
        "Authorization": "Bearer " + user.session_id
      }
    }).catch(err);
    if (resp && resp.ok) {
      let json = await resp.json().catch(err)
      setUsers(json)
      return
    }
    err()
  }, [applyGenericErrorSnack, user.session_id])

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getUsers()
    }
    return () => {
      mounted = false
    };
  }, [])

  return (
    <UserTable users={users} />
  );
}

export const ManageUsers = () => {
  return (
    <Grid container direction="column">
      <Typography variant="h4">
        Manage Users
      </Typography>
      <Route path="/admin/users" exact>
        <MainUsersPage/>
      </Route>
      <Route path="/admin/users/add" exact>
        <AddUser/>
      </Route>
      <Route path="/admin/users/:userID/manage" exact>
        <UpdateUser/>
      </Route>
    </Grid>
  );
}