import {Grid} from "@material-ui/core";
import CarForm from "./CarForm/CarForm";
import {Transactions} from "./Transactions";
import Typography from "@material-ui/core/Typography";
import {Notes} from "./Notes";


const Car = () => {
  return (
    <Grid container direction="column">
      <Typography variant="h4">
        Car
      </Typography>
      <CarForm />
      <Transactions/>
      <Notes/>
    </Grid>
  );
}

export default Car;