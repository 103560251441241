import {Button, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select,} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {useParams} from "react-router-dom";
import {useAuth} from "../../../services/authService";
import {Formik} from "formik";
import {ServiceEndpoint} from "../../../constants";
import * as Yup from "yup";
import {FormattedCurrencyFormField, FormField} from "../../formComponents/FormField";
import FormDateField from "../../formComponents/FormDateField";
import {useSnack} from "../../../services/snackService";
import {DeleteButton} from "../../formComponents/DeleteButton";


const validationSchema = Yup.object({
  date: Yup
    .date('Enter the date')
    .required('Date is required'),
  amount: Yup
    .number('Enter the amount')
    .min(0.01)
    .required('Amount is required'),
});

const useStyles = makeStyles((theme) => ({
  main: {
    flexGrow: 1,
    padding: "15px"
  },
  inputContainer: {
    flexGrow: 1
  },
  button: {
    width: "100%",
    margin: "10px 15px 0 15px"
  }
}));


const TransactionForm = ({transaction, reloadTransactions}) => {
  const {stockNumber} = useParams()
  const {user} = useAuth()
  const classes = useStyles();
  const {applySnack, applyGenericErrorSnack} = useSnack();
  const initialValues = transaction ? {...transaction} : {
    date: "",
    type: "Credit",
    vendor: "",
    description: "",
    reference_number: "",
    amount: "",
  }

  const handleSubmit = async (values, actions) => {
    const err = applyGenericErrorSnack("modify transaction")
    let url = ServiceEndpoint + `/api/1/car/${stockNumber}/transaction`
    if (transaction) {
      url = url + "/" + transaction.id
    }
    let resp = await fetch(url, {
      method: transaction ? "PUT" : "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + user.session_id
      },
      body: JSON.stringify(values)
    }).catch(err)
    if (resp.ok) {
      resp = await resp.json().catch(err)
      if (reloadTransactions) {
        reloadTransactions()
      }
      applySnack({
        severity: "success",
        message: transaction ? "Transaction Updated Successfully" : "Transaction Created Successfully"
      });
      actions.resetForm();
    } else {
      err()
    }
    actions.setSubmitting(false)
  }

  const handleDelete = async () => {
    const err = applyGenericErrorSnack("delete transaction")
    let url = ServiceEndpoint + `/api/1/car/${stockNumber}/transaction/${transaction.id}`
    let resp = await fetch(url, {
      method: "DELETE",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + user.session_id
      }
    }).catch(err)
    if (resp.ok) {
      if (reloadTransactions) {
        reloadTransactions()
      }
      applySnack({
        severity: "success",
        message: "Transaction Deleted Successfully"
      });
    } else {
      err()
    }
  }
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {formik => (
        <form onSubmit={formik.handleSubmit} className={classes.main}>
          <Grid container>
            <Grid container>
              <Grid item xs={12} md={2} className={classes.inputContainer}>
                <FormField label="Vendor" name="vendor" formik={formik}/>
              </Grid>
              <Grid item xs={12} md={10} className={classes.inputContainer}>
                <FormField label="Description" name="description" formik={formik}/>
              </Grid>
              <Grid item xs={12} sm={6} md={2} className={classes.inputContainer}>
                <FormField label="Reference Number" name="reference_number" formik={formik}/>
              </Grid>
              <Grid item xs={12} sm={6} md={2} className={classes.inputContainer}>
                <FormDateField label="Date" name="date" formik={formik} noTrash/>
              </Grid>
              <Grid item xs={12} sm={6} md={2} className={classes.inputContainer}>
                <FormControl style={{width: "100%"}}>
                  <InputLabel id="type">Type</InputLabel>
                  <Select
                    labelId="type"
                    id="type"
                    name="type"
                    error={formik.touched.date && Boolean(formik.errors.date)}
                    value={formik.values.type}
                    onChange={formik.handleChange}
                  >
                    <MenuItem value={"Credit"}>Credit</MenuItem>
                    <MenuItem value={"Debit"}>Debit</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={2} className={classes.inputContainer}>
                <FormattedCurrencyFormField
                  label="Amount"
                  name="amount"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                  formik={formik}/>
              </Grid>
              <Grid item xs={12} sm={6} md={2} className={classes.inputContainer}>
                <FormDateField
                  label={formik.values.type === "Credit"? "Paid Date" : "Collected Date"}
                  name="paid_or_collected_date"
                  formik={formik}/>
              </Grid>

            </Grid>
            <Button type="submit" variant="contained" color="primary"
                    className={classes.button}>{transaction ? "Update" : "Create"} Transaction</Button>
            {transaction &&
            <DeleteButton type="button" variant="outlined" className={classes.button} onClick={handleDelete}>
              Transaction
            </DeleteButton>
            }
          </Grid>
        </form>
      )}
    </Formik>
  );
}

export default TransactionForm