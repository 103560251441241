import { Grid, IconButton, TextField } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  button: {
    paddingBottom: 0,
    borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
    borderRadius: 0
  },
}));


const FormDateField = ({ formik, label, name, noTrash }) => {
  const classes = useStyles()
  return (
    <Grid container wrap="nowrap" direction="row">
      <TextField
        fullWidth
        id={name}
        name={name}
        label={label}
        type="date"
        InputLabelProps={{
          shrink: true
        }}
        value={formik.values[name]}
        onChange={formik.handleChange}
        error={formik.touched[name] && Boolean(formik.errors[name])}
        helperText={formik.touched[name] && formik.errors[name]}
      />
      {noTrash ||
        <IconButton aria-label="delete" className={classes.button} onClick={() => formik.setFieldValue(name, "")}>
          <DeleteIcon />
        </IconButton>
      }
    </Grid>
  );
}

export default FormDateField