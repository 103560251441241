import {createTheme, ThemeProvider} from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import CommuteIcon from '@material-ui/icons/Commute';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AssessmentIcon from '@material-ui/icons/Assessment';
import React from "react";
import Nav from "./components/nav/Nav";
import {teal, blue} from "@material-ui/core/colors";
import Login from "./components/Login";
import Dashboard from "./components/dashboard/Dashboard";
import { Route } from 'react-router-dom'
import Inventory from "./components/inventory/Inventory";
import CarForm from "./components/inventory/CarForm/CarForm";
import Private from "./components/Private";
import Car from "./components/inventory/Car";
import {ProvideSnack} from "./services/snackService";
import {ManageUsers} from "./components/admin/ManageUsers";
import {ReportHome} from "./components/reports/ReportHome";

const theme = createTheme({
  palette: {
    primary: {
      main: blue[800],
    },
    secondary: {
      main: teal[700],
    },
  },
  sideNavBar: {
    width: "240px"
  }
});


function App() {
  return (
    <ThemeProvider theme={theme}>
      <ProvideSnack>
        <Nav links={{
          any: [
            {to: "/", text: "Dashboard", icon: <HomeIcon/>, exact: true},
            {to: "/inventory", text: "Inventory", icon: <CommuteIcon/>},
          ],
          Admin: [
            {to: "/admin/users", text: "Manage Users", icon: <AccountCircleIcon/>},
            {to: "/reports", text: "Reports", icon: <AssessmentIcon/>}
          ]
        }}>
          <Route path="/login" component={Login}/>
          <Private>
            <Route path="/" exact component={Dashboard}/>
            {/*<Route path="/user/profile" exact component={Profile}/>*/}
            <Route path="/inventory" exact component={Inventory}/>
            <Route path="/inventory/car/" exact component={CarForm}/>
            <Route path="/inventory/car/:stockNumber" exact component={Car}/>
          </Private>
          <Private requiredRole={"Admin"}>
            <Route path="/admin/users/" component={ManageUsers}/>
            <Route path="/reports" component={ReportHome}/>
          </Private>
        </Nav>
      </ProvideSnack>
    </ThemeProvider>
  );
}

export default App;
