import {Button} from "@material-ui/core";
import {useAuth} from "../services/authService";
import {useState} from "react";
import {Formik} from "formik";
import * as Yup from 'yup';
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";


const validationSchema = Yup.object().shape({
  username: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
  password: Yup.string().max(255).min(8).required('Password is required')
})

const Login = () => {
  let {signin} = useAuth()
  const [error, setError] = useState(null)

  const handleSignin = async ({username, password}, actions) => {
    setError(false)
    let l = await signin(username, password)
    if (l.error) {
      setError(l.error)
    }
    actions.setSubmitting(false)
  }

  return (
    <Grid container justifyContent={"center"}>
      <Grid item>
        <Formik validationSchema={validationSchema} onSubmit={handleSignin}
                initialValues={{username: "", password: ""}}>
          {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
            <form onSubmit={handleSubmit}>
              <Box sx={{mb: 3}}>
                <Typography color="textPrimary" variant="h4" style={{marginTop: "30px", marginBottom: "0"}}>
                  Sign in
                </Typography>
                {error &&
                <Typography
                  color="textSecondary"
                  gutterBottom
                  variant="body2"
                >
                  {error}
                </Typography>
                }
              </Box>
              <TextField
                error={Boolean(touched.username && errors.username)}
                fullWidth
                helperText={touched.username && errors.username}
                label="Email Address"
                margin="normal"
                name="username"
                onBlur={handleBlur}
                onChange={handleChange}
                type="email"
                value={values.username}
                variant="outlined"
              />
              <TextField
                error={Boolean(touched.password && errors.password)}
                fullWidth
                helperText={touched.password && errors.password}
                label="Password"
                margin="normal"
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                type="password"
                value={values.password}
                variant="outlined"
              />
              <Box sx={{py: 2}}>
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Login
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
}

export default Login