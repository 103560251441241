import { useField } from 'formik';
import React from 'react';
import {FormControlLabel, Checkbox} from "@material-ui/core";

export const CheckboxField = ({name, label, style}, ...props) => {
  const [field, , helpers] = useField(name);

  const handleChange = (event) => {
    helpers.setValue(event.target.checked);
  };

  return <FormControlLabel
    control={<Checkbox {...props} checked={field.value} onChange={handleChange}/>}
    label={label}
    style={style}
  />
};