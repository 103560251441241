import { Grid, Typography } from "@material-ui/core";
import Inventory from "../inventory/Inventory";
import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
  widget: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
  },
}));

const Dashboard = () => {
  const classes = useStyles();
  return (
    <Grid container direction="row" wrap="wrap">
      <Grid item xs={12} className={classes.widget}>
        <Inventory defaultFilter="CurrentInventory" />
      </Grid>
      <Grid item xs={12} md={6} className={classes.widget}>
        <Inventory noAdd slim defaultFilter="SoldNotDelivered" />
      </Grid>
      <Grid item xs={12} md={6} className={classes.widget}>
        <Inventory noAdd slim defaultFilter="DeliveredNotCollected" />
      </Grid>
    </Grid>
  );
}

export default Dashboard