import { Fragment, useEffect, useState } from "react";
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@material-ui/core";
import { ServiceEndpoint } from "../../constants";
import { useAuth } from "../../services/authService";

export const ReportHome = () => {
  var date = new Date();
  var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  // var lastDay = new Date(date.getFullYear(), date.getMonth()+1, 0);

  const [start, setStart] = useState(`${firstDay.getFullYear()}-${("0" + firstDay.getMonth()).slice(-2)}-${("0" + firstDay.getDate()).slice(-2)}`)
  // const [end, setEnd] = useState(`${lastDay.getFullYear()}-${("0" + lastDay.getMonth()).slice(-2)}-${("0" + lastDay.getDate()).slice(-2)}`)
  const [end, setEnd] = useState("")
  const [action, setAction] = useState("notice-of-sale")
  const [totals, setTotals] = useState({})
  const { user } = useAuth();

  useEffect(() => {
    async function getData() {
      let resp = await fetch(ServiceEndpoint + "/api/1/transaction/current-inventory/purchase", {
        headers: {
          "Authorization": "Bearer " + user.session_id
        }
      })
      if (resp.ok) {
        let json = await resp.json()
        setTotals({ currentPurchases: json })
      }
    }
    getData();
  }, [])

  return (
    <Fragment>
      <h1>Report Home</h1>
      <Grid container direction="column">
        <Grid item container direction="row" style={{ padding: "15px 0" }}>
          <Grid item>
            <Typography variant="h6">
              Current Inventory - Purchase total:
            </Typography>
            <Typography variant="body1">
              {totals && totals.currentPurchases && totals.currentPurchases.toLocaleString()}
            </Typography>
          </Grid>
        </Grid>

        <form action={"/api/1/reports/" + action} method="GET">
          <Grid container spacing={2}>
            <Grid item>
              <TextField
                name="start_date"
                label="Start Date"
                type="date"
                InputLabelProps={{
                  shrink: true
                }}
                value={start}
                onChange={(event) => { setStart(event.target.value) }}
              />
            </Grid>
            <Grid item>
              <TextField
                name="end_date"
                label="End Date"
                type="date"
                InputLabelProps={{
                  shrink: true
                }}
                value={end}
                onChange={(event) => { setEnd(event.target.value) }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item>
              <FormControl>
                <InputLabel id="report-type-label">Report Type</InputLabel>
                <Select
                  labelId="report-type-label"
                  value={action}
                  onChange={(event) => { setAction(event.target.value) }}
                >
                  <MenuItem value="notice-of-sale">Notice of Sale</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <Button type="submit" variant="contained" color="primary">Download</Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Fragment>
  )
}
