import {useField, useFormikContext} from "formik";
import {Button, Grid, TextField} from "@material-ui/core";
import {useSnack} from "../../../services/snackService";

export const VinInput = (props) => {
  const {setFieldValue} = useFormikContext()
  const [meta] = useField(props);
  const {applyGenericErrorSnack, applySnack} = useSnack();

  const handleLookup = async () => {
    const err = applyGenericErrorSnack("vin lookup")
    let resp = await fetch(`https://vpic.nhtsa.dot.gov/api/vehicles/DecodeVinValues/${meta.value}?format=json`).catch(err)
    if (resp.ok) {
      resp = await resp.json().catch(err);
      setFieldValue("year", parseInt(resp.Results[0].ModelYear));
      setFieldValue("make", resp.Results[0].Make);
      setFieldValue("model", resp.Results[0].Model);
      setFieldValue("trim", resp.Results[0].Trim);
      applySnack({
        message: "Vin Lookup Successful.",
        severity: "success"
      })
    } else {
      err()
    }
  }

  return (
    <>
      <Grid container wrap="nowrap">
        <Grid item style={{flexGrow: 2}}>
          <TextField
            fullWidth
            {...props}
          />
        </Grid>
        <Grid item style={{flexGrow: 1}}>
          <Button type="button" disableElevation variant="contained" color="secondary" onClick={handleLookup}
                  style={{height: "100%", width: "100%"}}>Lookup VIN</Button>
        </Grid>
      </Grid>
    </>
  );
}