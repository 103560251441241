import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  main: {
    width: theme.sideNavBar.width,
    [theme.breakpoints.down('xs')]: {
      flexGrow: 1
    },
  },
}));

const SideNav = ({links}) => {
  const classes = useStyles();
  return (
    <nav className={classes.main}>
      {links}
    </nav>
  );
}

export default SideNav