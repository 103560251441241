import {createContext, useContext, useState} from "react";
import {Alert} from "@material-ui/lab";
import {Snackbar} from "@material-ui/core";


const snackContext = createContext(null);

export function ProvideSnack({children}) {
  const {applySnack, applyGenericErrorSnack, GlobalSnack} = useProvideSnack();
  return <snackContext.Provider value={{applySnack, applyGenericErrorSnack}}>
    <>
      <GlobalSnack/>
      {children}
    </>
  </snackContext.Provider>
}

export const useSnack = () => {
  return useContext(snackContext)
}

function useProvideSnack() {
  const [snack, setSnack] = useState()

  const applySnack = ({severity, message}) => {
    setSnack({
      severity: severity,
      message: message,
    })
  }

  const applyGenericErrorSnack = (eventName) => {
    return () => {
      applySnack({
        severity: "error",
        message: <p>
          An error has occurred while attempting to {eventName}, try refreshing.
          <br/>
          Contact support if error continues.
        </p>
      })
    }
  }

  const GlobalSnack = () => {
    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setSnack(null);
    };
    return snack? (
      <Snackbar open={!!snack} autoHideDuration={5000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={snack.severity}>
          {snack.message}
        </Alert>
      </Snackbar>
    ) : null;

  }
  return {applySnack, applyGenericErrorSnack, GlobalSnack}
}