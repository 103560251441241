import {TextField} from "@material-ui/core";
import {useState} from "react";


export const FormField = ({formik, name, label, ...props}) => {
  return (
    <TextField
      fullWidth
      id={name}
      name={name}
      label={label}
      value={formik.values[name]}
      onChange={formik.handleChange}
      error={formik.touched[name] && Boolean(formik.errors[name])}
      helperText={formik.touched[name] && formik.errors[name]}
      {...props}
    />
  );
}

export const FormattedNumberFormField = ({formik, name, label, ...props}) => {
  const [value, setValue] = useState(formik.values[name].toLocaleString())

  const onChange = (e) => {
    const formattedValue = (Number(e.target.value.replace(/\D/g, '')) || '').toLocaleString();
    setValue(formattedValue)
    formik.setFieldValue(name, Number(e.target.value.replace(/\D/g, '')))
  }

  return (
    <TextField
      fullWidth
      id={name}
      name={name}
      label={label}
      value={value}
      onChange={onChange}
      error={formik.touched[name] && Boolean(formik.errors[name])}
      helperText={formik.touched[name] && formik.errors[name]}
      inputProps={{
        inputMode: "numeric"
      }}
      {...props}
    />
  );
}


export const FormattedCurrencyFormField = ({formik, name, label, ...props}) => {
  const [value, setValue] = useState(formik.values[name].toLocaleString(undefined, {minimumFractionDigits: 2}))

  const onChange = (e) => {
    // // If more than 1 decimal place error
    let formattedValue = e.target.value.replace(/[^0-9.]/g, '')
    if ((formattedValue.match(/\./g) || []).length > 1) {
      formik.setFieldError(name, "Can only contain 1 decimal")
      return
    }
    // If more than 2 digits after decimal place error
    if ((formattedValue.match(/\.\d{3,}/g) || []).length > 0) {
      formik.setFieldError(name, "Can only contain 2 decimal places")
      return;
    }

    // Let the user still get the nice formatting even with a period added.
    if (formattedValue.endsWith(".")) {
      formattedValue = `${Number(formattedValue).toLocaleString()}.`
    } else if (formattedValue.endsWith(".0")) {
      formattedValue = `${Number(formattedValue).toLocaleString()}.0`
    } else if (formattedValue.endsWith(".00")) {
      formattedValue = `${Number(formattedValue).toLocaleString()}.00`
    } else if (formattedValue.match(/\.\d0$/)) {
      formattedValue = `${Number(formattedValue).toLocaleString()}0`
    } else {
      formattedValue = Number(formattedValue).toLocaleString()
    }

    setValue(formattedValue)
    formik.setFieldValue(name, Number(e.target.value.replace(/[^0-9.]/g, '')))
  }

  return (
    <TextField
      fullWidth
      id={name}
      name={name}
      label={label}
      value={value}
      onChange={onChange}
      error={formik.touched[name] && Boolean(formik.errors[name])}
      helperText={formik.touched[name] && formik.errors[name]}
      inputProps={{
        inputMode: "decimal"
      }}
      {...props}
    />
  );
}