import {useParams} from "react-router-dom";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Typography
} from "@material-ui/core";
import {useEffect, useState} from "react";
import {useAuth} from "../../../services/authService";
import {ServiceEndpoint} from "../../../constants";
import {useSnack} from "../../../services/snackService";
import {FieldArray, Form, Formik} from "formik";
import {FormField} from "../../formComponents/FormField";
import * as Yup from "yup";
import {makeStyles} from "@material-ui/core/styles";
import {RoleCheckBox} from "./common";
import {ResetPasswordForm} from "../../user/User";

const validationSchema = Yup.object({
  name: Yup
    .string('Enter the full name.')
    .required('Name is required.'),
  username: Yup
    .string('Enter a email.')
    .email('Must be a valid email.')
    .required('Email is required.'),
});

const useStyles = makeStyles((theme) => ({
  spacing: {
    marginTop: theme.spacing(1)
  }
}))

const UpdateUserForm = ({ourUser}) => {
  const {user} = useAuth();
  const {applySnack, applyGenericErrorSnack} = useSnack();
  const classes = useStyles();

  const handleSubmit = async (values, actions) => {
    const err = applyGenericErrorSnack("update user")
    let url = ServiceEndpoint + `/api/1/user/${ourUser.id}`
    let resp = await fetch(url, {
      method: "PUT",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + user.session_id
      },
      body: JSON.stringify(values)
    }).catch(err)
    if (resp.ok) {
      resp = await resp.json().catch(err)
      applySnack({
        severity: "success",
        message: "User Updated Successfully"
      });
    } else {
      err()
    }
    actions.setSubmitting(false)
  }
  return (
    <Formik
      initialValues={ourUser}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {formik => (
        <Form>
          <Grid container direction="column">
            <Typography variant="h5" className={classes.spacing}>
              Update User
            </Typography>
            <FormField label="Name" name="name" formik={formik} className={classes.spacing}/>
            <FormField label="Email" name="username" formik={formik} className={classes.spacing}/>
            <FieldArray
              name="roles"
              render={arrayHelpers => (
                <FormControl component="fieldset" className={classes.spacing}>
                  <FormLabel component="legend">Roles:</FormLabel>
                  <FormGroup>
                    <RoleCheckBox name="Admin" label="Admin" roles={formik.values.roles} arrayHelpers={arrayHelpers}/>
                  </FormGroup>
                </FormControl>
              )}
            />
            <FormControl>
              <FormLabel component="legend">User Is Active:</FormLabel>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values.is_active}
                    onChange={formik.handleChange}
                    name="is_active"
                    color="secondary"
                  />
                }
                label="Active"
              />
            </FormControl>
            <Button type="submit" variant="contained" color="primary">Update User</Button>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

export const UpdateUser = () => {
  const classes = useStyles();
  const {user} = useAuth();
  const {userID} = useParams();
  const {applySnack, applyGenericErrorSnack} = useSnack();
  const [ourUser, setOurUser] = useState();
  const [showResetPW, setShowResetPW] = useState();

  const getUser = async (user, userID) => {
    const err = applyGenericErrorSnack("fetch cars")
    let url = ServiceEndpoint + `/api/1/user/${userID}`
    let resp = await fetch(url, {
      headers: {
        "Authorization": "Bearer " + user.session_id
      }
    }).catch(err);
    if (resp && resp.ok) {
      let json = await resp.json().catch(err)
      setOurUser(json)
      return
    }
    err()
  }

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getUser(user, userID)
    }
    return () => {
      mounted = false
    };
  }, [userID])

  const unlockUser = async () => {
    const err = applyGenericErrorSnack("unlock user")
    let url = ServiceEndpoint + `/api/1/user/${ourUser.id}/unlock`
    let resp = await fetch(url, {
      method: "PATCH",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + user.session_id
      }
    }).catch(err)
    if (resp.ok) {
      resp = await resp.json().catch(err)
      applySnack({
        severity: "success",
        message: "User Unlocked Successfully"
      });
    } else {
      err()
    }
  }

  return (
    <Grid container direction="column">
      {ourUser &&
      <>
        <UpdateUserForm ourUser={ourUser}/>
        <Button className={classes.spacing} variant="contained" color="secondary" onClick={unlockUser}>Unlock User</Button>
        <Button className={classes.spacing} variant="contained" onClick={() => {setShowResetPW(!showResetPW)}}>Reset Password</Button>
        {showResetPW &&
        <ResetPasswordForm ourUser={ourUser} handleClose={() => {setShowResetPW(false)}}/>
        }
      </>
      }
    </Grid>
  )
}