import {Fragment, useCallback, useEffect, useState} from "react";
import {
  Avatar,
  Button,
  Divider,
  Grid,
  LinearProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import {ServiceEndpoint} from "../../constants";
import {Formik} from "formik";
import * as Yup from "yup";
import {FormField} from "../formComponents/FormField";
import {makeStyles} from "@material-ui/core/styles";
import {useAuth} from "../../services/authService";
import {useParams} from "react-router-dom";
import {useSnack} from "../../services/snackService";
import {DeleteButton, DeleteIconButton} from "../formComponents/DeleteButton";
import Hidden from "@material-ui/core/Hidden";
import {UserAvatar} from "../user/User";

const validationSchema = Yup.object({
  note: Yup
    .string('Enter the note')
    .required('Note is required'),
});

const useStyles = makeStyles((theme) => ({
  main: {
    padding: "15px"
  },
  inputContainer: {
    flexGrow: 1
  },
  submitButton: {
    width: "100%",
    margin: "15px"
  }
}));

const NoteForm = ({note, user, stockNumber, reloadNotes}) => {
  const classes = useStyles();
  const {applySnack, applyGenericErrorSnack} = useSnack();
  const initialValues = note ? {...note} : {note: ""}

  const handleSubmit = async (values, actions) => {
    const err = applyGenericErrorSnack("modify notes");
    let url = ServiceEndpoint + `/api/1/car/${stockNumber}/note`
    if (note) {
      url = url + "/" + note.id
    }
    let resp = await fetch(url, {
      method: note ? "PUT" : "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + user.session_id
      },
      body: JSON.stringify(values)
    }).catch(err)
    if (resp.ok) {
      resp = await resp.json().catch(err)
      if (reloadNotes) {
        reloadNotes()
      }
      applySnack({
        severity: "success",
        message: note ? "Note Updated Successfully" : "Note Created Successfully"
      });
      actions.resetForm();
    }
    actions.setSubmitting(false)
  }


  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {formik => (
        <form onSubmit={formik.handleSubmit}>
          <Grid container>
            <FormField label="Note" name="note" multiline formik={formik}/>
            <Button type="submit" variant="contained" color="primary" className={classes.submitButton}>
              {note ? "Update" : "Create"} Note
            </Button>
          </Grid>
        </form>
      )}
    </Formik>
  );
}

const NoteCard = ({user, note, index, handleDelete}) => {
  const [noteUser, setNoteUser] = useState(null);
  const {applyGenericErrorSnack} = useSnack();

  useEffect(() => {
    let mounted = true;

    const fetchData = async () => {
      const err = applyGenericErrorSnack("fetch public user")
      let resp = await fetch(ServiceEndpoint + `/api/1/user/${note.created_by_user_id}/public`, {
        headers: {
          "Authorization": "Bearer " + user.session_id
        }
      }).catch(err)
      if (resp.ok) {
        resp = await resp.json().catch(err)
        setNoteUser(resp)
      }
    }

    if (mounted) {
      fetchData()
    }
  }, []);

  return (
    <Fragment>
      {index !== 0 && <Divider component="li"/>}
      <ListItem>
        <ListItemAvatar>
          <UserAvatar user={noteUser}/>
        </ListItemAvatar>
        <ListItemText
          style={{
            whiteSpace: "pre-wrap"
          }}
          primary={noteUser && noteUser.name}
          secondary={
            <Fragment>
              <Typography component="span" variant="body2" color="textPrimary" style={{display: "inline"}}>
                {new Date(note.datetime).toLocaleDateString()}
                {" "}
                {new Date(note.datetime).toLocaleTimeString()}
              </Typography>
              {` — ${note.note}`}
              <br/>
              <Hidden smUp>
                <DeleteIconButton type="button" onClick={() => {handleDelete({note, index})}}>note</DeleteIconButton>
              </Hidden>
              <Hidden xsDown>
                <DeleteButton type="button"  variant="outlined" onClick={() => {handleDelete({note, index})}}>note</DeleteButton>
              </Hidden>
            </Fragment>
          }
        />

      </ListItem>
    </Fragment>
  );
}

export const Notes = () => {
  const {stockNumber} = useParams()
  const {user} = useAuth()
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(true);
  const [notes, setNotes] = useState(null);
  const {applySnack, applyGenericErrorSnack} = useSnack();

  const getNotes = useCallback(async () => {
    const err = applyGenericErrorSnack("fetch notes")
    let resp = await fetch(ServiceEndpoint + `/api/1/car/${stockNumber}/note`, {
      headers: {
        "Authorization": "Bearer " + user.session_id
      }
    }).catch(err);
    if (resp.ok) {
      let json = await resp.json().catch(err)
      setNotes(json)
      setLoading(false)
      return
    }
    err()
  }, [])

  useEffect(() => {
    let mounted = true;
    if (open && mounted && !notes) {
      getNotes()
    }
    return () => {
      mounted = false
    };
  }, [])

  const handleDelete = async ({note, index}) => {
    const err = applyGenericErrorSnack("delete note")
    let url = ServiceEndpoint + `/api/1/car/${stockNumber}/note/${note.id}`
    let resp = await fetch(url, {
      method: "DELETE",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + user.session_id
      }
    }).catch(err)
    if (resp.ok) {
      applySnack({
        severity: "success",
        message: "Note Deleted Successfully"
      });
      let n = [...notes]
      n.splice(index, 1)
      setNotes(n);
    } else {
      err()
    }
  }

  return (
    <Grid container direction="column">
      <Typography variant="h4" onClick={() => setOpen(!open)}>
        Notes
        <IconButton aria-label="expand row" size="medium">
          {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
        </IconButton>
      </Typography>
      {open &&
      <>
        {loading ? <LinearProgress color="secondary"/> :
          <>
            <List>
              {!notes &&
              <ListItem>No Notes...</ListItem>
              }
              {notes && notes.map((note, index) =>
                <NoteCard user={user} note={note} index={index} key={note.id} handleDelete={handleDelete}/>
              )}
            </List>
            <Grid item style={{margin: "15px"}}>
              <NoteForm user={user} stockNumber={stockNumber} reloadNotes={getNotes}/>
            </Grid>
          </>
        }
      </>
      }
    </Grid>
  );
}