import {Fragment, useCallback, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useAuth} from "../../services/authService";
import {ServiceEndpoint} from "../../constants";
import Hidden from "@material-ui/core/Hidden";
import {
  Button,
  Collapse,
  Grid,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell as OldTableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import TransactionForm from "./TransactionForm/TransactionFrom";
import Typography from "@material-ui/core/Typography";
import {useSnack} from "../../services/snackService";

const TableCell = (props) => {
  return <OldTableCell style={{padding: "10px 4px", whiteSpace: "nowrap"}} {...props}/>
}

function Row(props) {
  const {transaction, triggerReload} = props;
  const [open, setOpen] = useState(false);

  return (
    <Fragment>
      <TableRow style={{background: transaction.paid_or_collected_date && (transaction.type === "Credit" ? "#FED2D2" : "#E5FED2")}}
                onClick={() => setOpen(!open)}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
          </IconButton>
        </TableCell>
        <Hidden mdUp>
          <TableCell>{transaction.date}</TableCell>
          <TableCell style={{
            wordWrap: "break-word"
          }}
          >{transaction.description}</TableCell>
          <TableCell align="right">${transaction.amount.toLocaleString(undefined, {minimumFractionDigits: 2})}</TableCell>
        </Hidden>
        <Hidden smDown>
          <TableCell>{transaction.date}</TableCell>
          <TableCell>{transaction.type}</TableCell>
          <TableCell>{transaction.vendor}</TableCell>
          <TableCell>{transaction.description}</TableCell>
          <TableCell>{transaction.reference_number}</TableCell>
          <TableCell>{transaction.paid_or_collected_date}</TableCell>
          <TableCell align="right">$ {transaction.amount.toLocaleString(undefined, {minimumFractionDigits: 2})}</TableCell>
        </Hidden>
      </TableRow>
      <TableRow>
        <TableCell style={{padding: 0, margin: 0}} colSpan={70}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <TransactionForm transaction={transaction} reloadTransactions={() => {
              triggerReload();
              setOpen(false);
            }}/>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}

export const Transactions = () => {
  const {stockNumber} = useParams();
  const {user} = useAuth();
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(true);
  const [transactions, setTransactions] = useState(null);
  const [totals, setTotals] = useState(null);
  const [createOpen, setCreateOpen] = useState(false);
  const {applyGenericErrorSnack} = useSnack()

  const getTotals= useCallback(async () => {
    const err = applyGenericErrorSnack("fetch transaction totals")
    let resp = await fetch(ServiceEndpoint + `/api/1/car/${stockNumber}/transaction/totals`, {
      headers: {
        "Authorization": "Bearer " + user.session_id
      }
    }).catch(err);
    if (resp.ok) {
      let json = await resp.json().catch(err)
      setTotals(json)
      return
    }
    err()
  }, [])

  const getTransactions = useCallback(async () => {
    const err = applyGenericErrorSnack("fetch transactions")
    getTotals()
    let resp = await fetch(ServiceEndpoint + `/api/1/car/${stockNumber}/transaction`, {
      headers: {
        "Authorization": "Bearer " + user.session_id
      }
    }).catch(err);
    if (resp.ok) {
      let json = await resp.json().catch(err)
      setTransactions(json)
      setLoading(false)
      return
    }
    err()
  }, [])

  useEffect(() => {
    let mounted = true;
    if (open && mounted) {
      getTransactions()
    }
    return () => {
      mounted = false
    };
  }, [open])

  return (
    <Grid item container direction="column">
      <Typography variant="h4" onClick={() => setOpen(!open)}>
        Transactions
        <IconButton aria-label="expand row" size="medium">
          {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
        </IconButton>
      </Typography>
      {open &&
      <>
        {
          loading ? <LinearProgress color="secondary"/> :
            <Grid container>
              {createOpen ?
                <TransactionForm reloadTransactions={getTransactions}/>
                :
                <Grid item container>
                  <Button onClick={() => setCreateOpen(true)} variant="contained" color="secondary"
                          style={{width: "100%", margin: "15px"}}
                  >
                    Create Transaction
                  </Button>
                </Grid>
              }

              <TableContainer component={Paper} >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell/>
                      <Hidden mdUp>
                        <TableCell>Date</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell align="right">Amount</TableCell>
                      </Hidden>
                      <Hidden smDown>
                        <TableCell>Date</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>Vendor</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>ReferenceNumber</TableCell>
                        <TableCell>Paid/Collected Date</TableCell>
                        <TableCell align="right">Amount</TableCell>
                      </Hidden>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!transactions &&
                    <TableRow>
                      <TableCell colSpan={8}>No Transactions...</TableCell>
                    </TableRow>
                    }
                    {transactions && transactions.map((i) => <Row key={i.id} transaction={i}
                                                                  triggerReload={getTransactions}/>)}
                    <TableRow>
                      <TableCell colSpan={8}/>
                    </TableRow>
                    <TableRow>
                      <Hidden mdUp>
                        <TableCell rowSpan={5} colSpan={1}/>
                      </Hidden>
                      <Hidden smDown>
                        <TableCell rowSpan={5} colSpan={5}/>
                      </Hidden>
                    </TableRow>
                    <TableRow>
                      <TableCell/>
                      <TableCell align="right">All</TableCell>
                      <TableCell align="right">Paid/Collected</TableCell>
                    </TableRow>
                    <TableRow style={{background: "#E5FED2"}}>
                      <TableCell>Debits:</TableCell>
                      <TableCell align="right">$ {totals && totals.debits.toLocaleString(undefined, {minimumFractionDigits: 2})}</TableCell>
                      <TableCell align="right">$ {totals && totals.debits_collected.toLocaleString(undefined, {minimumFractionDigits: 2})}</TableCell>
                    </TableRow>
                    <TableRow style={{background: "#FED2D2"}}>
                      <TableCell>Credits:</TableCell>
                      <TableCell align="right">$ {totals && totals.credits.toLocaleString(undefined, {minimumFractionDigits: 2})}</TableCell>
                      <TableCell align="right">$ {totals && totals.credits_paid.toLocaleString(undefined, {minimumFractionDigits: 2})}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Total:</TableCell>
                      <TableCell align="right">$ {(totals && (totals.debits - totals.credits).toLocaleString(undefined, {minimumFractionDigits: 2}))}</TableCell>
                      <TableCell align="right">$ {(totals && (totals.debits_collected - totals.credits_paid).toLocaleString(undefined, {minimumFractionDigits: 2}))}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
        } </>
      }
    </Grid>
  );
}