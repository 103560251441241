import {
  Button,
  FormControl,
  FormGroup,
  Grid,
  InputAdornment, InputLabel,
  LinearProgress, MenuItem, Select,
  useMediaQuery,
  useTheme
} from "@material-ui/core";
import {useHistory, useParams} from "react-router-dom";
import {Fragment, useState} from "react";
import {useAuth} from "../../../services/authService";
import {Formik} from "formik";
import {ServiceEndpoint} from "../../../constants";
import * as Yup from "yup";
import {CarPopulate} from "./CarPopulate";
import {VinInput} from "./VinInput";
import {makeStyles} from "@material-ui/core/styles";
import FormDateField from "../../formComponents/FormDateField";
import {FormattedCurrencyFormField, FormattedNumberFormField, FormField} from "../../formComponents/FormField";
import {useSnack} from "../../../services/snackService";
import {DeleteButton} from "../../formComponents/DeleteButton";
import {CheckboxField} from "../../formComponents/CheckBoxField";


const validationSchema = Yup.object({
  year: Yup
    .number('Enter the year')
    .min(1886)
    .max(new Date().getFullYear() + 2)
    .required('Year is required'),
  make: Yup
    .string('Enter the make')
    .required('Make is required'),
  model: Yup
    .string('Enter the model')
    .required('Model is required'),

});

const useStyles = makeStyles((theme) => ({
  main: {
    padding: "15px"
  },
  inputContainer: {
    flexGrow: 1
  },
  button: {
    width: "100%",
    margin: "10px 15px 0 15px"
  }
}));

const CarForm = ({car}) => {
  const {stockNumber} = useParams();
  const theme = useTheme();
  const onMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [loading, setLoading] = useState(!!stockNumber);
  const [oldStock, setOldStock] = useState(false);
  const {applySnack, applyGenericErrorSnack} = useSnack()
  const {user} = useAuth();
  let history = useHistory();
  const classes = useStyles();
  const initialValues = {
    stock_number: 0,
    year: "",
    make: "",
    model: "",
    trim: "",
    color: "",
    miles: 0,
    vin: "",
    options: "",
    asking: 0,
    car_location: "",
    title_location: "",
    title_number: "",
    purchased_from: "",
    date_purchased: "",
    date_collected: "",
    sold_to: "",
    date_sold: "",
    date_delivered: "",
    tag: "",
  }

  const handleSubmit = async (values, actions) => {
    const err = applyGenericErrorSnack("modify car")
    let url = ServiceEndpoint + "/api/1/car"
    if (stockNumber) {
      url = url + `/${stockNumber}`
    }
    let resp = await fetch(url, {
      method: stockNumber ? "PUT" : "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + user.session_id
      },
      body: JSON.stringify(values)
    }).catch(err)
    if (resp.ok) {
      resp = await resp.json().catch(err)
      applySnack({
        severity: "success",
        message: "Car Updated Successfully"
      })
      if (!stockNumber) {
        history.push(`/inventory/car/${resp.stock_number}`)
      }
    } else {
      err()
    }
    actions.setSubmitting(false)
  }

  const handleDelete = async () => {
    const err = applyGenericErrorSnack("delete car")
    let url = ServiceEndpoint + `/api/1/car/${stockNumber}`
    let resp = await fetch(url, {
      method: "DELETE",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + user.session_id
      }
    }).catch(err)
    if (resp.ok) {
      history.push("/inventory")
      applySnack({
        severity: "success",
        message: "Car and Associated Data Deleted Successfully"
      });
    } else {
      err()
    }
  }

  return (
    <Fragment>
      {loading ? <LinearProgress color="secondary"/> : null}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {formik => (
          <form onSubmit={formik.handleSubmit} className={classes.main}>
            {stockNumber &&
            <CarPopulate stockNumber={stockNumber} setLoading={setLoading}/>
            }
            {loading ? null :
              <Grid container>
                <Grid container  item style={{
                  padding: "0 0 1em 0"
                }}>
                  <FormControl variant="outlined" style={{
                    margin: "0 0 1em 0",
                    minWidth: "130px"
                  }}>
                    <InputLabel id="demo-simple-select-outlined-label">Tag Color</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        name="tag"
                        value={formik.values.tag}
                        onChange={formik.handleChange}
                        label="Tag Color"
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value="lightblue">🔵 Blue</MenuItem>
                      <MenuItem value="orange">🟠 Orange</MenuItem>
                      <MenuItem value="lightgreen">🟢 Green</MenuItem>
                      <MenuItem value="mediumpurple">🟣 Purple</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid container item>
                  {!oldStock && !stockNumber &&
                  <Grid item className={classes.inputContainer} xs={onMobile && 12}>
                    <Button
                      onClick={() => {
                        setOldStock(true)
                      }}
                      variant="contained" color="secondary"
                    >Enter Old Stock</Button>
                  </Grid>
                  }
                  {(oldStock || stockNumber) &&
                  <Grid item className={classes.inputContainer} xs={onMobile && 12}>
                    <FormField type="number" disabled={!!stockNumber || !oldStock} label="Stock Number"
                               name="stock_number" formik={formik}/>
                  </Grid>
                  }
                  <Grid item className={classes.inputContainer} xs={onMobile && 12}>
                    <FormField label="Year" name="year" formik={formik} type="number"/>
                  </Grid>
                  <Grid item className={classes.inputContainer} xs={onMobile && 12}>
                    <FormField label="Make" name="make" formik={formik}/>
                  </Grid>
                  <Grid item className={classes.inputContainer} xs={onMobile && 12}>
                    <FormField label="Model" name="model" formik={formik}/>
                  </Grid>
                  <Grid item className={classes.inputContainer} xs={onMobile && 12}>
                    <FormField label="Trim" name="trim" formik={formik}/>
                  </Grid>
                  <Grid item className={classes.inputContainer} xs={onMobile && 12}>
                    <FormField label="Color" name="color" formik={formik}/>
                  </Grid>
                  <Grid item className={classes.inputContainer} xs={onMobile && 12}>
                    <FormattedNumberFormField label="Miles" name="miles" formik={formik}/>
                  </Grid>
                </Grid>
                <Grid container item xs={onMobile && 12}>
                  <VinInput
                    id="vin"
                    name="vin"
                    label="VIN"
                    value={formik.values.vin}
                    onChange={formik.handleChange}
                    error={formik.touched.vin && Boolean(formik.errors.vin)}
                    helperText={formik.touched.vin && formik.errors.vin}
                  />
                </Grid>
                <Grid item className={classes.inputContainer} xs={onMobile && 12}>
                  <FormField multiline label="Options" name="options" formik={formik} xs={onMobile && 12}/>
                </Grid>
                <Grid item lg={4} className={classes.inputContainer}>
                  <FormattedCurrencyFormField
                    label="Asking Price"
                    name="asking"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                    formik={formik}/>
                </Grid>
                <Grid container item lg={8}>
                  <Grid item className={classes.inputContainer}>
                    <FormField label="Car Location" name="car_location" formik={formik}/>
                  </Grid>
                  <Grid item className={classes.inputContainer}>
                    <FormGroup row>
                      <FormField label="Title Location" name="title_location" formik={formik}
                                 style={{width: "auto", flexGrow: 1, minWidth: 250}}/>
                      <CheckboxField name="title_ready" label="Title Ready"/>
                      <FormField label="Title Number" name="title_number" formik={formik}
                                 style={{width: "auto", flexGrow: 1, minWidth: 250}}/>
                    </FormGroup>
                  </Grid>
                </Grid>
                <Grid container item xs={12}>
                  <Grid item xs={12} md={6} className={classes.inputContainer}>
                    <FormField label="Purchased From" name="purchased_from" formik={formik}/>
                  </Grid>
                  <Grid item xs={12} md={6} className={classes.inputContainer}>
                    <FormDateField formik={formik} label="Date Purchased" name="date_purchased"/>
                  </Grid>
                </Grid>
                <Grid container item xs={12}>
                  <Grid item xs={12} md={6} className={classes.inputContainer}>
                    <FormField label="Sold To" name="sold_to" formik={formik}/>
                  </Grid>
                  <Grid item xs={12} md={6} className={classes.inputContainer}>
                    <FormDateField name="date_sold" label="Date Sold" formik={formik}/>
                  </Grid>
                  <Grid item xs={12} md={6} className={classes.inputContainer}>
                    <FormDateField name="date_delivered" label="Date Delivered" formik={formik}/>
                  </Grid>
                  <Grid item xs={12} md={6} className={classes.inputContainer}>
                    <FormDateField name="date_collected" label="Date Collected" formik={formik}/>
                  </Grid>
                </Grid>
                <Button type="submit" variant="contained" color="primary" className={classes.button}>
                  {stockNumber ? "Update" : "Create"} Car
                </Button>
                {stockNumber &&
                <DeleteButton type="button" variant="outlined" className={classes.button} onClick={handleDelete}>
                  Car
                </DeleteButton>
                }
              </Grid>
            }
          </form>
        )}
      </Formik>
    </Fragment>
  );
}

export default CarForm