import {createContext, useContext, useEffect, useState} from "react";
import {ServiceEndpoint} from "../constants";

const authContext = createContext(null);
// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}
// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  const auth = useContext(authContext)
  if (auth.user && new Date(auth.user.expires) < Date.now()) {
    auth.signout()
  }
  return auth;
};
// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const [user, setUser] = useState(null);

  const getMe = async (user) => {
    let resp = await fetch(ServiceEndpoint+"/api/1/user/me", {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + user.session_id
      }
    })
    let json = await resp.json()
    await setUser(json)
  }

  const signin = async (username, password) => {
    let resp = await fetch(ServiceEndpoint+"/api/1/user/login", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({username: username, password: password})
    })
    if (resp.ok) {
      let respUser = await resp.json();
      setUser(respUser);
      localStorage.removeItem("user");
      localStorage.setItem("user", JSON.stringify(respUser));

      let timeOffset = new Date(respUser.expires) - Date.now()
      setTimeout(() => {
        localStorage.removeItem("user");
        setUser(null);
      }, timeOffset)
      return {user: respUser}
    }
    let {error} = await resp.json()
    return {error}
  };

  const signout = () => {
    fetch(ServiceEndpoint+"/api/1/user/logout", {
      headers: {
        "Authorization": "Bearer " + user.session_id
      }
    });
    localStorage.removeItem("user");
    setUser(null);
  };


  useEffect(() => {
    let mounted = true;
    let lsUser = localStorage.getItem("user")
    console.log("Get user from local storage")
    let timeout
    if (lsUser && mounted) {
      lsUser = JSON.parse(lsUser)
      console.log("Checking User")
      getMe(lsUser)
      if (new Date(lsUser.expires) < Date.now()) {
        localStorage.removeItem("user");
        setUser(null);
      }
      setUser(lsUser)
      let timeOffset = new Date(lsUser.expires) - Date.now()
      timeout = setTimeout(() => {
        localStorage.removeItem("user");
        setUser(null);
      }, timeOffset)
    }
    return () => {
      mounted = false
      timeout && clearTimeout(timeout)
    };
  }, []);
  // Return the user object and auth methods
  return {
    user,
    signin,
    signout,
  };
}