import {Checkbox, FormControlLabel} from "@material-ui/core";

export const RoleCheckBox = ({arrayHelpers, roles, name, label}) => {
  let roleIndex = roles? roles.findIndex((r) => r === name) : -1
  let checked = roleIndex > -1
  const handleChange = () => {
    if (checked) {
      arrayHelpers.remove(roleIndex)
    } else {
      arrayHelpers.push(name)
    }
  }
  return (
    <FormControlLabel
      control={<Checkbox checked={checked} onChange={handleChange} name={name}/>}
      label={label}
    />
  );
}