import { useCallback, useEffect, useState } from "react";
import {
  Button, FormControl,
  Grid, Hidden, IconButton, InputLabel,
  LinearProgress, MenuItem,
  Paper, Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow, TextField,
  Typography
} from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import { ServiceEndpoint } from "../../constants";
import { useAuth } from "../../services/authService";
import { useSnack } from "../../services/snackService";
import SearchIcon from "@material-ui/icons/Search";

const Row = ({ car, user, history, slim }) => {
  const [totals, setTotals] = useState(null);
  const { applyGenericErrorSnack } = useSnack();

  const getTotal = async () => {
    const err = applyGenericErrorSnack("fetch transaction totals")
    let resp = await fetch(ServiceEndpoint + `/api/1/car/${car.stock_number}/transaction/totals`, {
      headers: {
        "Authorization": "Bearer " + user.session_id
      }
    }).catch(err);
    if (resp.ok) {
      let json = await resp.json().catch(err)
      setTotals(json)
      return
    }
    err()
  }

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getTotal()
    }
    return () => {
      mounted = false
    };
  }, [])
  return (
    <TableRow key={car.stock_number} onClick={() => history.push(`inventory/car/${car.stock_number}`)}
      style={{ background: car.date_sold ? "lightcoral" : car.tag ? car.tag : null }}>
      <TableCell>{car.stock_number}</TableCell>
      <TableCell>{car.year} {car.make} {car.model}</TableCell>
      <Hidden xsDown xsUp={!!slim}>
        <TableCell>{car.trim}</TableCell>
        <TableCell>{car.miles.toLocaleString()}</TableCell>
      </Hidden>
      <Hidden smDown xsUp={!!slim}>
        <TableCell>{car.vin}</TableCell>
      </Hidden>
      <Hidden mdDown xsUp={!!slim}>
        <TableCell>{car.car_location}</TableCell>
        <TableCell>{car.title_location}</TableCell>
      </Hidden>
      <TableCell>{car.purchased_from !== "" && car.purchased_from.match(/\b(\w)/g).join('')}</TableCell>
      <TableCell align="right">{totals && (totals.debits_collected - totals.credits).toLocaleString(undefined, { minimumFractionDigits: 2 })}</TableCell>
    </TableRow>
  );
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Inventory = ({ noAdd, defaultFilter, slim }) => {
  const { user } = useAuth();
  const { applyGenericErrorSnack } = useSnack();
  const history = useHistory();
  const [cars, setCars] = useState(null);
  const qSearch = useQuery().get("search")
  const [filter, setFilter] = useState(defaultFilter ? defaultFilter : localStorage.getItem("inventoryFilter"));
  const [search, setSearch] = useState(qSearch || "");

  const getCars = useCallback(async (s) => {
    setCars(null);
    const err = applyGenericErrorSnack("fetch cars")
    let url = new URL(ServiceEndpoint + "/api/1/car"),
      params = { filter_preset: filter, s: s }
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
    let resp = await fetch(url, {
      headers: {
        "Authorization": "Bearer " + user.session_id
      }
    }).catch(err);
    if (resp && resp.ok) {
      let json = await resp.json().catch(err)
      setCars(json)
      return
    }
    err()
  }, [filter])

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getCars(qSearch)
    }
    return () => {
      mounted = false
    };
  }, [filter, qSearch])

  return (
    <>
      <Typography variant="h6">
        {filter && filter.replace(/([a-z])([A-Z])/g, `$1 $2`)} {cars && `(${cars.length})`}
      </Typography>

      {!cars ? <LinearProgress color="secondary" />
        :
        <Grid container wrap="wrap" justifyContent="space-evenly" style={{ height: "100%" }}>
          {!defaultFilter &&
            <Grid container direction="row" style={{ padding: "15px" }}>
              <FormControl style={{ width: "160px" }}>
                <InputLabel id="type">Filter:</InputLabel>
                <Select
                  value={filter}
                  onChange={(e) => {
                    localStorage.setItem("inventoryFilter", e.target.value)
                    setFilter(e.target.value)
                  }}
                >
                  <MenuItem value={"CurrentInventory"}>Current Inventory</MenuItem>
                  <MenuItem value={"All"}>All</MenuItem>
                  <MenuItem value={"Sold"}>Sold</MenuItem>
                  <MenuItem value={"SoldNotDelivered"}>Sold Not Delivered</MenuItem>
                  <MenuItem value={"DeliveredNotCollected"}>Delivered Not Collected</MenuItem>
                </Select>
              </FormControl>
              <Grid item>
                <form onSubmit={(e) => {
                  e.preventDefault()
                  history.push(`/inventory?search=${encodeURIComponent(search)}`)
                }}>
                  <Grid container wrap="nowrap" direction="row">
                    <TextField
                      fullWidth
                      label="Search"
                      placeholder="2014 bmw 335i"
                      value={search}
                      onChange={(e) => {
                        setSearch(e.target.value)
                      }}
                    />
                    <IconButton type="submit" style={{
                      paddingBottom: 0,
                      borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
                      borderRadius: 0
                    }}>
                      <SearchIcon />
                    </IconButton>
                  </Grid>
                </form>
              </Grid>
            </Grid>
          }
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Stock #</TableCell>
                  <TableCell>Car</TableCell>
                  <Hidden xsDown xsUp={!!slim}>
                    <TableCell>Trim</TableCell>
                    <TableCell>Miles</TableCell>
                  </Hidden>
                  <Hidden smDown xsUp={!!slim}>
                    <TableCell>VIN</TableCell>
                  </Hidden>
                  <Hidden mdDown xsUp={!!slim}>
                    <TableCell>Car Location</TableCell>
                    <TableCell>Title Location</TableCell>
                  </Hidden>
                  <TableCell>From</TableCell>  
                  <TableCell align="right">Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!noAdd &&
                  <TableRow>
                    <TableCell colSpan={70} align="center" style={{ padding: 0 }}>
                      <Button variant="contained" color="primary" style={{ width: "100%" }}
                        onClick={() => history.push("inventory/car")}>
                        Add Car
                      </Button>
                    </TableCell>
                  </TableRow>
                }
                {cars && cars.map((i) => <Row key={i.stock_number} user={user} car={i} history={history} slim={slim} />)}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>}
    </>
  );
}

export default Inventory;
