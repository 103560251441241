import PropTypes from "prop-types";
import {
  Avatar, Button,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {useAuth} from "../../services/authService";
import {useSnack} from "../../services/snackService";
import {ServiceEndpoint} from "../../constants";
import {Form, Formik} from "formik";
import {FormField} from "../formComponents/FormField";
import * as Yup from "yup";

const useStyles = makeStyles((theme) => ({
  spacing: {
    marginTop: theme.spacing(1)
  }
}))

const validationSchema = Yup.object().shape({
  current_pw: Yup.string().max(255).min(8).required('Password is required'),
  new_pw: Yup.string().max(255).min(8).required('Password is required'),
})

export const ResetPasswordForm = ({ourUser, handleClose}) => {
  const classes = useStyles();
  const {user} = useAuth();
  const {applySnack, applyGenericErrorSnack} = useSnack();
  // If we don't supply a user use the current.
  if (!ourUser) {
    ourUser = user
  }

  const handleSubmit = async (values, actions) => {
    const err = applyGenericErrorSnack("update user password")
    let url = ServiceEndpoint + `/api/1/user/${ourUser.id}/set-password`
    let resp = await fetch(url, {
      method: "PATCH",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + user.session_id
      },
      body: JSON.stringify(values)
    }).catch(err)
    if (resp.ok) {
      resp = await resp.json().catch(err)
      applySnack({
        severity: "success",
        message: "User Password Updated Successfully"
      });
    } else {
      err()
    }
    actions.resetForm();
    actions.setSubmitting(false)
  }

  const labels = {
    current_pw: user.id === ourUser.id? "Old Password": "Your Password",
    new_pw: user.id === ourUser.id? "New Password": "User's New Password",
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{
        current_pw: "",
        new_pw: ""
      }}
      validationSchema={validationSchema}
    >
      {formik =>
        <Form>
          <FormField name="current_pw" label={labels.current_pw} type="password" formik={formik}/>
          <FormField name="new_pw" label={labels.new_pw} type="password" formik={formik}/>
          <div style={{display: "flex", justifyContent: "space-between"}}>
            <Button className={classes.spacing} variant="contained" color="primary" type="submit">Update</Button>
            <Button className={classes.spacing} variant="outlined" color="secondary" type="button"
            onClick={handleClose}
            >Cancel</Button>
          </div>
        </Form>
      }
    </Formik>
  );
}
ResetPasswordForm.propType = {
  ourUser: PropTypes.shape({
    name: PropTypes.string
  }),
  handleClose: PropTypes.func.isRequired
}

export const UserAvatar = ({user, ...props}) => {
   return (
    <Avatar alt={user && user.name} {...props}>
      {user && user.name && user.name.match(/\b(\w)/g).join('')}
    </Avatar>
  );
}

UserAvatar.propType = {
  user: PropTypes.shape({
    name: PropTypes.string
  })
}
