import {useAuth} from "../services/authService";
import Login from "./Login";
import React from "react";
import PropTypes from "prop-types";

const Private = ({children, requiredRole}) => {
  const {user} = useAuth();
  console.log("User in private?:", user);
  if (requiredRole && user) {
    if (user.roles && (user.roles.includes(requiredRole) || user.roles.includes("Admin"))) {
      return children
    }
    return null
  } else if (requiredRole && !user) {
    return null
  } else if (user) {
    return children
  }

  return <Login/>
}
Private.propTypes = {
  children: PropTypes.node.isRequired,
  requiredRole: PropTypes.string
}
export default Private

