import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from '@material-ui/icons/Close';
import Hidden from "@material-ui/core/Hidden";
import { Fragment, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import { NavLink as RouterLink } from "react-router-dom";
import PropTypes from 'prop-types';
import Link from "@material-ui/core/Link";
import { useAuth } from "../../services/authService";
import { ExitToApp } from "@material-ui/icons";
import NavSearch from "./NavSearch";
import SideNav from "./SideNav";
import { Avatar, Menu, MenuItem, Modal, useMediaQuery, useTheme } from "@material-ui/core";
import { ResetPasswordForm, UserAvatar } from "../user/User";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: "240px"
    }
  },
  navSearch: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  main: {
    height: "100%",
    display: "flex",
    justifyContent: "left",
    flexDirection: "row",
    marginBottom: "20vh"
  },
  content: {
    flexGrow: 1,
    margin: theme.spacing(2)
  },
  linksList: {
    margin: 0,
    padding: 0,
  },
  activeLink: {
    display: "block",
    background: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  }
}))


const Profile = () => {
  const { user, signout } = useAuth();
  const [anchorEl, setAnchorEl] = useState();
  const [resetPWModal, setResetPWModal] = useState();

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  }

  const handleClose = () => {
    setAnchorEl(null);
    setResetPWModal(false);
  }

  return user ? (
    <>
      <UserAvatar user={user} style={{ marginLeft: "10px" }} onClick={handleClick} />
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => { setResetPWModal(true) }}>Change Password</MenuItem>
        <MenuItem onClick={signout}>Logout</MenuItem>
      </Menu>
      <Modal
        open={resetPWModal}
        onClose={handleClose}
      >
        <div style={{
          display: "flex",
          justifyContent: "space-around",
        }}>
          <div style={{
            width: 400,
            backgroundColor: "whitesmoke",
            marginTop: 50,
            padding: 50,
          }}>
            <Typography variant="h6">
              Change Password
            </Typography>
            <ResetPasswordForm handleClose={handleClose} />
          </div>
        </div>
      </Modal>
    </>
  ) : null
}

const Nav = ({ links, children }) => {
  const classes = useStyles();
  const [menuOpen, setMenuOpen] = useState(false);
  const { user, signout } = useAuth()
  const theme = useTheme();
  const onMobile = useMediaQuery(theme.breakpoints.down('xs'));


  const handleDrawerToggle = () => {
    setMenuOpen(!menuOpen);
  };

  if (user === null && menuOpen) {
    setMenuOpen(false)
  }

  const drawer = (
    <div className={classes.drawer}>
      <List className={classes.linksList}>
        <ListItem>
          <NavSearch style={{ width: "100%", margin: 0 }} closeNav={onMobile ? setMenuOpen : null} />
        </ListItem>
        <Divider />
        {links.any.map((link, index) => (
          <Link to={link.to} key={index} component={RouterLink} exact={link.exact} activeClassName={classes.activeLink}
            onClick={() => onMobile && setMenuOpen(false)}>
            <ListItem button>
              <ListItemIcon>{link.icon}</ListItemIcon>
              <ListItemText primary={link.text} />
            </ListItem>
          </Link>
        ))}
        {user && user.roles && user.roles.includes("Admin") &&
          <>
            <Divider />
            {links.Admin.map((link, index) => (
              <Link to={link.to} key={index} component={RouterLink} exact={link.exact} activeClassName={classes.activeLink}
                onClick={() => onMobile && setMenuOpen(false)}>
                <ListItem button>
                  <ListItemIcon>{link.icon}</ListItemIcon>
                  <ListItemText primary={link.text} />
                </ListItem>
              </Link>
            ))}
          </>
        }
        <Divider />
        {user &&
          <ListItem button onClick={signout}>
            <ListItemIcon><ExitToApp /></ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        }
      </List>
    </div>
  );

  return (
    <Fragment>
      <AppBar position="relative">
        <Toolbar>

          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            {menuOpen ? <CloseIcon /> : <MenuIcon />}
          </IconButton>

          <Typography variant="h6" noWrap>
            Dealers Auto Outlet
          </Typography>
          <div className={classes.grow} />
          {user &&
            <>
              <Hidden xsDown>
                <NavSearch className={classes.navSearch} />
                <div className={classes.grow} />
              </Hidden>
              <Hidden smDown>
                <Typography variant="body1" noWrap>
                  Hello, {user.name}
                </Typography>
              </Hidden>
              <Profile />
            </>
          }
        </Toolbar>
      </AppBar>
      <div className={classes.main}>
        {menuOpen &&
          <SideNav links={drawer} />
        }
        <Hidden xsDown={menuOpen} implementation="css" className={classes.content}>
          {children}
        </Hidden>
      </div>
    </Fragment>
  )
}

Nav.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string.isRequired,
      icon: PropTypes.node.isRequired,
      text: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired
}

export default Nav