import {
  Button,
  createTheme,
  Dialog, DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  ThemeProvider
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import WarningIcon from '@material-ui/icons/Warning';
import {red} from "@material-ui/core/colors";
import {useState} from "react";

const theme = createTheme({
  palette: {
    primary: red,
  },
});

export const DeleteButton = ({onClick, ...props}) => {
  const [confirm, setConfirm] = useState(false);

  const handleClick = () => {
    if (confirm) {
      onClick()
    } else {
      setConfirm(true)
      setTimeout(() => {setConfirm(false)}, 2000)
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Button
        {...props}
        color="primary"
        onClick={handleClick}
        startIcon={confirm? <WarningIcon/> : <DeleteIcon/>}
        endIcon={confirm && <WarningIcon/>}
      >
        {confirm? "Are you sure?":
          <>
            Delete {props.children}
          </>
        }
      </Button>
    </ThemeProvider>
  )
}


export const DeleteIconButton = ({onClick, ...props}) => {
  const [confirm, setConfirm] = useState(false);

  const handleClick = () => {
    if (confirm) {
      onClick()
    } else {
      setConfirm(true)
      setTimeout(() => {setConfirm(false)}, 10000)
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <IconButton
        {...props}
        color="primary"
        onClick={handleClick}
      >
        <DeleteIcon fontSize="small"/>
      </IconButton>

      <Dialog
        open={confirm}
        onClose={() => {setConfirm(false)}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This action is unrecoverable, are you sure you want to delete {props.children}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {setConfirm(false)}} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleClick} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  )
}
