import {useEffect} from "react";
import {useAuth} from "../../../services/authService";
import {useFormikContext} from "formik";
import {ServiceEndpoint} from "../../../constants";
import {useSnack} from "../../../services/snackService";

export const CarPopulate = ({stockNumber, setLoading}) => {
  // TODO Pull this out and put it higher then pass as inital value to form instead. Can stop unneeded updates that way.
  const {user} = useAuth()
  const {setFieldValue} = useFormikContext()
  const {applyGenericErrorSnack} = useSnack()

  useEffect(() => {
    let mounted = true;
    const getCar = async () => {
      const err = applyGenericErrorSnack("populate car")
      let resp = await fetch(ServiceEndpoint + `/api/1/car/${stockNumber}`, {
        headers: {
          "Authorization": "Bearer " + user.session_id
        }
      }).catch(err);
      if (resp.ok) {
        let json = await resp.json().catch(err)
        for (const [key, value] of Object.entries(json)) {
          setFieldValue(key, value)
        }
        setLoading(false)
        return
      }
      err()
    }
    if (mounted) {
      getCar()
    }
    return () => {
      mounted = false
    };
  }, [])
  // }, [applyGenericErrorSnack, setFieldValue, setLoading, stockNumber, user.session_id])


  return null
}